import React from "react";

import { graphql } from "gatsby";
import PropTypes from "prop-types";

import AllComponents from "../content/AllComponents";
import Header from "../layouts/Header";
import MainLayout from "../layouts/MainLayout";
import Seo from "../Seo/Seo";

const ContactPage = ({ data: { page } }) => (
  <MainLayout
    variant="Black"
    header={(
      <Header
        headline={page.headline}
        bgImageUrl="url(/images/backgrounds/black.webp)"
        variant="overlay"
        theme="dark"
      />
    )}
  >
    {page.contentElements && (
      <AllComponents elements={page.contentElements} />
    )}
  </MainLayout>
);

export const query = graphql`
  query ($id: String!) {
    page: sanityContactPage(id: {eq: $id}) {
      id
      Metadata {
        ...MetaData
      }
      tagList {
        tags {
          url
          label
        }
        useJumpmarks
      }
      title
      slug {
        current
      }
      headline
      _rawDescription
      contentElements {
        ...SectionHeader
        ...ServiceCardList
        ...SolutionCardList
        ...OfficeCardList
        ...ContentCardList
        ...CtaCard
        ...ContentSection
        ...ContactSection
      }
    }
 }
`;

ContactPage.propTypes = {
  data: PropTypes.oneOfType([PropTypes.object]).isRequired,
};

const seo = ({ data }) => (
  <Seo data={data.page?.Metadata} />
);

seo.propTypes = {
  data: PropTypes.oneOfType([PropTypes.object]).isRequired,
};

export const Head = seo;

export default ContactPage;
