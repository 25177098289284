import React from "react";

import { graphql } from "gatsby";
import PropTypes from "prop-types";

import SeoComponent from "../../components/Seo/Seo";

const Seo = ({ data = null }) => (
  <SeoComponent
    title={data?.metatitle}
    description={data?.metadescription}
  />
);

export const query = graphql`
  fragment MetaData on SanityMetadata {
    metadescription
    metatitle
    og_description
    og_title
    twitter_creator
    twitter_description
    twitter_site
    twitter_title
    twitter_image {
      hotspot {
        height
        width
        x
        y
      }
      crop {
        bottom
        left
        right
        top
      }
      asset {
        altText
        description
        url
        size
        path
        gatsbyImageData(
          aspectRatio: 1.5
          backgroundColor: ""
          breakpoints: 10
          fit: CLIP
          formats: NO_CHANGE
          height: 100
          layout: FIXED
          outputPixelDensities: 1.5
          placeholder: DOMINANT_COLOR
          sizes: ""
          width: 50
        )
      }
    }
  }
`;

Seo.propTypes = {
  data: PropTypes.oneOfType([PropTypes.object]),
};

export default Seo;
